<template>
    <div>
        <v-container class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.clients.list_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>

                </v-flex>
            </v-layout>
            <form @submit.prevent="addClients" autocomplete="off">
                <v-layout row wrap>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_name_ku" type="text"
                            :label="$store.getters.language.data.clients.client_name_ku" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_name_en" type="text"
                            :label="$store.getters.language.data.clients.client_name_en" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_name_ar" type="text"
                            :label="$store.getters.language.data.clients.client_name_ar" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>


                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="clients.client_index" type="number"
                            :label="$store.getters.language.data.clients.client_index" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" :loading="loading_btn" type="submit">
                            {{$store.getters.language.data.clients.add_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card flat>
                        <v-card-text>
                            <v-data-table :loading="loading" :headers="headers" :search="search" :items="rows"
                                class="elevation-0" item-key="client_id">
                                <template v-slot:[`item.client_id`]="{ item }">
                                    <div>
                                        <v-btn icon :to="'/clients-list/'+item.client_id" color="teal" class="mx-1">
                                            <v-icon> mdi-pencil-outline </v-icon>
                                        </v-btn>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on">
                                                    <v-icon> mdi-delete-outline </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-text>
                                                    {{$store.getters.language.data.clients.delete_question}}
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text class="elevation-0">
                                                        {{$store.getters.language.data.clients.cancel_btn}}
                                                    </v-btn>
                                                    <v-btn color="error" class="elevation-0"
                                                        @click="deleteClients(item.client_id)">
                                                        {{$store.getters.language.data.clients.yes_btn}}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                </template>
                                <template v-slot:[`item.client_logo`]="{ item }">
                                    <img v-if="item.client_logo" :src="$imagePath+item.client_logo"
                                        style="border-radius: 4px;" class="mx-1 my-1" width="40" height="40" />
                                    <div v-else>
                                        <v-icon size="55" color="black" class="mx-n1">mdi-image</v-icon>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>

                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/clients.request.js'
    import * as xlsx from 'xlsx/xlsx.js';

    export default {

        data() {
            return {
                clients: {},
                search: '',
                loading: true,
                loading_btn: false,

                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows: [],

                selected_clients: {},
                headers: [


                    {
                        text: this.$store.getters.language.data.clients.client_name_ku,
                        align: 'start',
                        sortable: true,
                        value: 'client_name_ku',
                    },
                    {
                        text: this.$store.getters.language.data.clients.client_name_en,
                        align: 'start',
                        sortable: true,
                        value: 'client_name_en',
                    },
                    {
                        text: this.$store.getters.language.data.clients.client_name_ar,
                        align: 'start',
                        sortable: true,
                        value: 'client_name_ar',
                    },
                    {
                        text: this.$store.getters.language.data.clients.client_logo,
                        align: 'start',
                        sortable: true,
                        value: 'client_logo',
                    },
                    {
                        text: this.$store.getters.language.data.clients.client_index,
                        align: 'start',
                        sortable: true,
                        value: 'client_index',
                    }, {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'client_id',
                    }
                ],
            }
        },
        computed: {
            page() {
                const pageKey = 'CLIENTS'
                return {}
            }
        },
        mounted() {
            this.readClients();
        },
        methods: {
            exportExcel() {
                const list = this.rows
                let result = []
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    let obj = {
                        client_id: item.client_id,
                        client_name_ku: item.client_name_ku,
                        client_name_en: item.client_name_en,
                        client_name_ar: item.client_name_ar,
                        client_logo: item.client_logo,
                        client_index: item.client_index,
                    }
                    result.push(obj)
                }
                var f = xlsx.utils.json_to_sheet(result)
                var wb = xlsx.utils.book_new()
                xlsx.utils.book_append_sheet(wb, f, 'sheet')
                xlsx.writeFile(wb, result.length + ' rows of ' + 'Clients.xlsx')
            },
            addClients() {
                this.loading_btn = true
                requests.createClients(this.clients).then(r => {
                        if (r.status == 200) {
                            this.clients = {}
                            this.rows.push(
                                r.data.new_data
                            )
                            this.snackbar = {
                                value: true,
                                text: 'Clients Added',
                                color: 'success'
                            }
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to add Clients',
                                color: 'error'
                            }
                        }
                    })
                    .finally(() => {
                        this.loading_btn = false
                    })

            },
            deleteClients(client_id) {
                requests.deleteClients(client_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f => {
                            return f.client_id != client_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Clients Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },

            readClients() {
                this.loading = true
                requests.getAllClients().then(r => {
                        if (r.status == 200) {
                            this.rows = r.data.clients

                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read Clients',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Clients',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            selectClients(i) {
                this.selected_clients = i
                this.delete_dialog = true
            },
        },
    }
</script>